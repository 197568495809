@import 'variables';

@font-face {
	font-family: 'Roboto';
	src:
		url('#{$fontLink}/Roboto-Regular.woff2') format('woff2'),
		url('#{$fontLink}/Roboto-Regular.woff') format('woff'),
		url('#{$fontLink}/Roboto-Regular.svg#Roboto-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:
		url('#{$fontLink}/Roboto-Bold.woff2') format('woff2'),
		url('#{$fontLink}/Roboto-Bold.woff') format('woff'),
		url('#{$fontLink}/Roboto-Bold.svg#Roboto-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:
		url('#{$fontLink}/Roboto-Medium.woff2') format('woff2'),
		url('#{$fontLink}/Roboto-Medium.woff') format('woff'),
		url('#{$fontLink}/Roboto-Medium.svg#Roboto-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:
		url('#{$fontLink}/Roboto-Light.woff2') format('woff2'),
		url('#{$fontLink}/Roboto-Light.woff') format('woff'),
		url('#{$fontLink}/Roboto-Light.svg#Roboto-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:
		url('#{$fontLink}/Roboto-Thin.woff2') format('woff2'),
		url('#{$fontLink}/Roboto-Thin.woff') format('woff'),
		url('#{$fontLink}/Roboto-Thin.svg#Roboto-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}
