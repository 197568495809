@import 'normalize-scss/sass/normalize';

$iconsLinkNew: '~src/assets/icons/new';
$texturesLink: '~src/assets/textures';
$imagesLink: '~src/assets/images';
$iconsLink: '~src/assets/icons';
$fontLink: '~src/assets/font';

$fontFamily: 'Roboto', sans-serif;

$default-gray: #707070;

$yellow: #f3ca3e;
$yellow-hover: #f5be09;
$grey: #969696;
$border-grey: #f0f0f0;
$border-dark-gray: #d9d9d9;

$bg-silver: #fcfcfc;
$bg-gray: #f1f1f1;
$bg-darkgray: #e0e0e0;
$gray-text: #3b3c3c;

$white: #ffffff;
$black: #000000;
$simple-gray: #c1c1c1;
$country-bg: #f3f5f8;
$back-gray: #d4d4d4;
$light-gray: #dedede;
$icon-active-gray: #8b8b8b;
$icon-gray: #cccccc;
$icon-bg-active: #ededed;
$icon-bg: #fbfbfb;
$icon-green: #07b500;
$circle-gray: #eae7e7;
$brand-color: #16416c; // BRAND COLOR
$light-brand-color: #f3f5f8;
$interior-color: #00a14b;
$blue-text: #004171;
$inactive-color: #5c7d97;
$color-gray-default: #c6c6c6;
$blind-item-bg: #f3f5f8;
$brand-dark-color: #1d426a;

$yellow-color: #fca500;
$placeholder-color: #8d8d8d;
$input-bg: #f3f5f8;
$error-color: #bf0000;

$main-text-color: #555555;
$text-gray: #bcbcbc;

$frame-circle-border: #d3d3d3;
$frame-yellow-color: #fec42d;
$car-premium-border: #ffc525;
$retailer-box-shadow: #00000029;

$light-blue: #f8fbff;
$bg-light-gray: #f6f9f9;
$green: #166c6c;
$light-blue-text: #577594;

$global-width: 100%;
$content-width: 90%;

$toolbar-width: 470px;
$toolbar-title-size: 40px;
$toolbar-animation-speed: 0.15s;
$toolbar-animation-function: ease-in;

$toggle-color-on: $brand-color;
$toggle-color-off: $circle-gray;
$toggle-control-color: $white;
$toggle-width: 40px;
$toggle-height: 20px;
$toggle-gutter: 3px;
$toggle-radius: 50%;
$toggle-control-speed: 0.15s;
$toggle-control-ease: ease-in;
$toggle-radius: calc($toggle-height / 2);
$toggle-control-size: $toggle-height - ($toggle-gutter * 2);
$shadow:
	2px 2px 10px rgba($black, 0.05),
	-2px -2px 10px rgba($black, 0.05);
$modal-shadow:
	-2px -2px 10px 0px rgba($black, 0.05),
	2px 2px 10px 0px rgba($black, 0.05);
$modal-backdrop-background: rgba(9, 30, 51, 0.3);

$country-selector-item-border: #d5d7d9;
